@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Modern-H';
    src: url('/src/fonts/ModernH-Medium.woff2') format('woff2'),
        url('/src/fonts/ModernH-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Edit';
    src: url('/src/fonts/EditSerifCyrillicSemiBold.woff2') format('woff2'),
        url('/src/fonts/EditSerifCyrillicSemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


body {
    overflow-x: hidden;
    font-family: 'Modern-H', sans-serif;
}

h2 {
    font-family: "Edit", sans-serif;
    font-weight: 700;
    font-style: normal;
}

p,
a {
    font-family: "Modern-H", sans-serif;
    font-weight: 400;
    font-style: normal;
}

img {
    border-radius: 20px;
    width: 100%;
}

a {
    height: 100%;
    width: 100%;
}

.header {
    background-color: #167495;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    -webkit-box-shadow: 0px 16px 26px -10px rgba(34, 60, 80, 0.33);
    -moz-box-shadow: 0px 16px 26px -10px rgba(34, 60, 80, 0.33);
    box-shadow: 0px 16px 26px -10px rgba(34, 60, 80, 0.33);
}
.active {
    text-decoration: underline;
    text-decoration-color: white;
    color: white;}

.bm-burger-button {
    display: none;
    position: fixed;
    width: 36px;
    height: 30px;
    right: -36px;
    top: 36px;
    transition: right 0.3s ease;
  }
  .bm-burger-button.bm-burger-button-open {
    right: 36px;
  }
  
  .bm-burger-bars {
    background: white;
  }
  
  .bm-burger-bars-hover {
    background: white;
  }
  
  .bm-cross-button {
    position: absolute !important;
    top: 30px !important;
    left: 130px !important;
    height: 30px !important;
    width: 30px !important;
    
  }
  
  .bm-cross {
    background: #bdc3c7;
  }

  
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 175px !important;
    top: 0 !important;
    right: -175px;
    transition: right 0.3s ease;
  }
  
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    position: fixed;
    height: 100%;
    font-size: 1.15em;
    width: 175px !important;
    top: 0 !important;
    right: -175px;
    transition: right 0.3s ease;
  }

  
  .bm-morph-shape {
    fill: #373a47;
  }
  
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  .bm-item {
    display: inline-block;
    height: auto;
    margin-bottom: 10px;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;

  }
  
  .bm-overlay-open {
    opacity: 1;
    pointer-events: auto;
  }


.containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
}

.logo img {
    width: 250px;
    transition: transform 0.2s ease-in-out;
}
.logo:hover img {
    transform: scale(1.05);
}

.menu ul {
    display: flex;
    gap: 25px;
}

.menu li,
.menu a {
    font-size: 18px;
    /* text-decoration: none; */
    color: #fff;
    list-style: none;
}

.footer {
    background: #191b24;
    font-family: 'Modern-H', sans-serif;
    text-align: center;
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.footer .row {
    width: 100%;
    margin-top: 30px;
    color: gray;
    font-size: 20px;
    display: flex;
    gap: 25px;
    justify-content: center;

}

.footer .row a {
    text-decoration: none;
    color: gray;
    transition: 0.5s;
}

.footer .row a:hover {
    color: #fff;
}

.footer .row ul {
    width: 100%;
    height: 50%;
}

.footer .row ul li {
    display: inline-block;
    margin: 0px 30px;
}

.footer .row a i {
    margin: 0% 1%;
}

.linkFooter {
    height: 60px;
}

.iconFooter {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: invert(100%);
}

/* AIzaSyBwN_cOYu6fxnuh56FE4NBR8VHTUVazZKM */

.section {
    padding: 75px 0;
}

.section:nth-child(1) {
    padding-top: 150px;
}

.section:nth-child(2) {
    padding: 50px 0px 50px;
    background: rgb(93, 195, 231);
    background: linear-gradient(0deg, rgba(93, 195, 231, 1) 0%,
            rgba(226, 244, 251, 0.7495448863139005) 65%,
            rgba(255, 255, 255, 0.6683123933167017) 100%);
    -webkit-box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
    -moz-box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
    box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
}

.section:nth-child(4) {
    padding: 50px 0px 50px;
    background: rgb(93, 195, 231);
    background: linear-gradient(0deg, rgba(93, 195, 231, 1) 0%,
            rgba(226, 244, 251, 0.7495448863139005) 65%,
            rgba(255, 255, 255, 0.6683123933167017) 100%);
    -webkit-box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
    -moz-box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
    box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
}

.section:nth-child(7) {
    padding: 50px 0px 50px;
    background: rgb(93, 195, 231);
    background: linear-gradient(0deg, rgba(93, 195, 231, 1) 0%,
            rgba(226, 244, 251, 0.7495448863139005) 65%,
            rgba(255, 255, 255, 0.6683123933167017) 100%);
    -webkit-box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
    -moz-box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
    box-shadow: 0px 28px 16px -18px rgba(34, 60, 80, 0.18);
}

.container {
    width: 86%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    gap: 50px;
}

.container p {
    font-size: 18px;
}

.containerReverse {
    width: 86%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    margin: auto;
    gap: 50px;

}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#containerRecall {
    border: 5px solid #1d97c1;
    border-radius: 20px;
}

.discription {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
    width: 50%;
}

.discription h2 {
    font-size: 56px;
}

.discription p {
    font-size: 20px;
}
.pay { 
    display: flex;
    justify-content: space-between;
    
}
.pay  img {
    width: 250px;
    height: auto;
}

.image {
    width: 500px;
}

.btn {

    border-radius: 10px;
    border: none;
    background-color: #1d97c1;
    color: white;
    font-family: 'Modern-H';
    font-size: 1.3125rem;
    cursor: pointer;
    height: 60px;
    width: 120px;
}

.btn:hover {
    background-color: #38b5df;
}

.form {
    font-family: 'Modern-H', sans-serif;
    font-weight: 400;
    font-style: normal;
    width: 50%;
}

.recall {
    width: 85%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    line-height: 1.4;
    padding: 35px;
}

.recall p {
    font-size: 30px;
}

.recall h2 {
    font-size: 36px;
}

.textContactUs {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
}

.textContactUs h2 {
    font-size: 40px;
}

.textContactUs p {
    font-size: 20px;
}

.row {
    width: 50%;
}

.firstRow {
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-family: "Modern-H", sans-serif;
    font-weight: 400;
    font-style: normal;

}

.firstRow input {
    border: none;
    background: #e6e6e6;
    margin-top: 15px;
    padding: 10px 15px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: "Modern-H", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.fixedWidthTextarea {
    width: 100%;
    resize: vertical;
    margin-top: 15px;
    border: none;
    background: #e6e6e6;
    border-radius: 5px;
    padding-left: 15px;
    padding-top: 15px;
    font-family: "Modern-H", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.formBtn {
    padding: 12px 60px;
    border-radius: 10px;
    border: none;
    background-color: #1d97c1;
    color: white;
    font-family: 'Modern-H', sans-serif;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    float: right;
}

.mapStyle {
    width: 700px;
    height: 400px;
}

.containerTable {
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #5dc3e7;
    border-collapse: collapse;
    border-radius: 10px;
    margin: auto;
    overflow: hidden;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table th,
.table td {
    padding: 10px;
    text-align: left;
}

.table th {
    background-color: #167495;
    border: 1px solid #5dc3e7;
    color: white;

}

.table td {
    font-size: 16px;
}

.table td:not(:first-child) {
    border-left: 1px solid #5dc3e7;

}

.service-name {
    font-weight: bold;
}

.price {
    font-size: 18px;
    color: #1d97c1;
}

.special-price {
    font-size: 18px;
    font-weight: bold;
    color: #ff69b4;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 86%;
    font-size: 40px;
}

.title h2 {
    font-size: 56px;
}

.popup {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup.show {
    visibility: visible;
    opacity: 1;
}

.popup h2 {
    color: #333;
    margin-top: 0;
}

.popup p {
    color: #555;
}

.iconPopUp {
    font-size: 48px;
    margin-bottom: 10px;
}

.iconPopUp-success {
    width: 100px;
    height: 100px;
}

.iconPopUp-error {
    width: 100px;
    height: 100px;

}

.row.error input {
    border: 1px solid #dc3545;
}

.row.error p,
.rowText.error p {
    color: #dc3545;
    margin-bottom: 10px;
    display: block;
}

.containerWrapper {
    width: 95%;
    margin-left: 50px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 70px;
    margin-top: 30px;
}


.containerSlider {
    width: 90%;
    display: flex;
    align-items: center;
    gap: 50px;
}



.slider {
    position: relative;
    width: 848px;
    height: 565px;
    margin-top: 25px;
    background-color: #f7f7f7;
    border: 5px solid #5dc3e7;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

}

.sliderImages {
    border-radius: 0;
    width: 100%;
    height: auto;
    display: inline-block;
    box-sizing: border-box;
}

.sliderImages img {
    margin: 0;
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: 0px;
    object-fit: cover;
    /* transform: scale(); */
}

.textWrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    margin-top: 25px;
    padding: 10px 0;
}

.sliderTextName {
    font-size: 50px;
    font-weight: bold;
    text-align: left;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.sliderTextSpecialist {
    font-size: 25px;
    font-style: italic;
    text-align: left;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.sliderTextDescription {
    font-size: 16px;
    text-align: left;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.slider-controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;
    box-sizing: border-box;
}

.left-controls,
.right-controls {
    display: flex;
    flex-direction: column;
}

.prev,
.next {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 10px 0;
}

.prev {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 10px 0;
    transform: rotate(180deg);
}

.prev img,
.next img {
    width: 60px;
    height: 60px;
}

.prev:hover img,
.next:hover img {
    opacity: 0.7;
}

.containerTitle {
    display: flex;
}


.language-switcher {
    display: flex !important;
    gap: 20px;

}

.buttonLanguage {
    background: none;
    border: none;
    font-size: 15px;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    padding: 0;
}

.buttonLanguage::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: transparent;
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: background-color 0.3s ease;
}

.buttonLanguage:disabled::after {
    background-color: #fff;
}

.buttonLanguage:disabled {
    cursor: default;
}
/* NotFound.css */
.notfound-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    color: #333;
}

.notfound-title {
    font-size: 56px;
    margin-bottom: 10px;
    color: red;
}

.notfound-description {
    font-size: 32px;
    margin-bottom: 50px;
    display: flex;
    text-align: center;
}

.notfound-home-link {
    width: 240px;
    height: 80px;
    font-size: 24px;
    color: white;
    background-color: #5dc3e7;
    border-radius: 5px;
    text-decoration: none;
    margin: auto;
}

.notfound-home-link:hover {
    background-color: #1d97c1;
}

@media screen and (max-width: 1240px) {
    .discription h2 {
        font-size: 64px;
    }

    .discription p,
    a {
        font-size: 20px;
    }

    .image {
        width: 450px;
    }

    .btn {
        padding: 12px 16px;
        border-radius: 10px;
        border: none;
        background-color: #1d97c1;
        color: white;
        font-size: 14px;
        cursor: pointer;
    }

    .firstRow {
        width: 100%;
    }

    .mapStyle {
        width: 400px;
        height: 400px;
    }

    .containerSlider {
        max-width: 100%;
        gap: 0;
        height: auto;
    }

    .sliderImages img {
        max-height: 565px;
        object-fit: cover;
    }

    .slider {
        height: auto;
    }

    .containerWrapper {
        gap: 10px;
    }

    .textWrapper {
        width: 60%;
        padding: 0;
        margin-top: 40px;

    }
    




}

@media screen and (max-width: 768px) {

    .logo img {
        width: 200px;
    }

    .burgerBtn .line {
        width: 20px;
        height: 3px;
        background-color: whitesmoke;
        border-radius: 5px;
    }

    .menu ul li {
        margin: 10px 0;
    }

    .wrapper {
        flex-direction: column;
    }

    .container {
        width: 90%;
        flex-direction: column;
    }

    .containerReverse {
        width: 90%;
        flex-direction: column;
    }

    .discription {
        width: 90%;
    }

    .discription h2 {
        font-size: 52px;
    }

    .discription p,
    a {
        font-size: 20px;
    }

    .image {
        width: 450px;
    }

    .btn {
        padding: 12px 16px;
        border-radius: 10px;
        border: none;
        background-color: #1d97c1;
        color: white;
        font-size: 14px;
        cursor: pointer;
    }

    .recall {
        width: 90%;
    }

    .recall h2:nth-child(1) {
        font-size: 26px;
    }
    .recall p {
        font-size: 22px;
    }

    .footer .row ul li {
        display: inline-block;
        margin: 0px 10px;
    }

    .textContactUs {
        width: 100%;
    }

    .form {
        width: 100%;
    }

    .formBtn {
        padding: 10px 40px;
        border-radius: 10px;
        border: none;
        background-color: #1d97c1;
        color: white;
        font-family: 'Modern-H', sans-serif;
        font-size: 14px;
        cursor: pointer;
        margin-top: 15px;
        float: right;
    }

    .mapStyle {
        width: 650px;
        height: 450px;
    }

    .containerSlider {
        display: flex;
        flex-direction: column;
        width: 1000px;
        height: auto;

    }

    .slider {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .containerWrapper {
        display: flex;
        height: 10%;
        flex-direction: column;
        margin: auto;
        gap: 0;
    }

    .textWrapper {
        width: 80%;
    }
}

@media screen and (max-width: 540px) {
    .burgerBtn {
        display: flex;
    }
    .menu>ul {
        display: none;
    }

    .bm-menu {
        background: #373a47;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
        width: 40%;
        position: fixed;
        right: 0px;
        top: 0px 
    }

    .burgerBtn .line {
        width: 20px;
        height: 3px;
        background-color: whitesmoke;
        border-radius: 5px;
    }

    .bm-burger-button {
        display: block;
        position: fixed;
        width: 25px;
        height: 25px;
        right: 30px;
        top: 20px;
    }

    .menu ul li {
        margin: 10px 0;
        flex-direction: column;
    }

    .wrapper {
        flex-direction: column;
    }

    .container {
        width: 90%;
    }

    .containerReverse {
        width: 90%;
        flex-direction: column;
    }

    .discription {
        width: 90%;
    }

    .discription h2 {
        font-size: 42px;
    }

    .discription p {
        font-size: 16px;
    }
    .pay  img {
        width: 180px;
        height: auto;
    }
    .pay {
        margin: auto;
    }

    .image {
        width: 90%;
    }

    .btn {
        padding: 12px 16px;
        border-radius: 10px;
        border: none;
        background-color: #1d97c1;
        color: white;
        font-size: 14px;
        cursor: pointer;
    }

    .footer {
        text-align: left;
        padding: 5%;
        height: auto;
    }

    .footer .row ul li {
        display: block;
        /* margin: 5px 0px; */
        text-align: left;
        font-size: 28px;
    }

    .footer .row a i {
        margin: 0% 3%;
    }
    .row h3 {
        font-size: 12px;
        margin: auto;
    }

    .logo img {
        width: 175px;
    }

    .mapStyle {
        width: 350px;
        height: 350px;
    }

    .mapDiscription {
        display: flex;
        margin: auto;

    }

    /* .container title {
        font-size: ;
    } */

    .containerSlider {
        display: flex;
        flex-direction: column;
        width: 1000px;
        height: auto;
    }

    .slider {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .sliderTextName {
        font-size: 40px;
    }

    .containerWrapper {
        display: flex;
        height: 10%;
        flex-direction: column;
        margin: auto;
        gap: 0;
    }

    .textWrapper {
        width: 80%;
        margin-top: 20px;
        margin-left: 0;
    }

    .table th,
    .table td {
        padding: 10px;
        text-align: center;
        font-size: 18px;
    }

    .title {
        font-size: 36px;
    }

    .title h2 {
        font-size: 42px;
    }

    .recall {
        width: 85%;
        display: flex;
        gap: 20px;
        flex-direction: column;
        line-height: 1.4;
    }

    .recall p {
        font-size: 18px;
    }

    .recall h2 {
        font-size: 22px;
    }
    .table{
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }
    .containerTable{
        width: 100%;
    }

    .table th, .table td {
        text-align: center;
        padding: 0px; /* Отступы для ячеек */
    }

    .table th {
        font-size: 16px;
        padding: 10px;
        
    }
    
    .table td {
        font-size: 12px;
        padding: 7px;
        padding-bottom: 5px;

    }
        .table th:nth-child(1),
        .table td:nth-child(1) {
            width: 35%;
            /* text-align: left; */
        }
    
        .table th:nth-child(2),
        .table td:nth-child(2) {
            width: 40%;
        }
    
        .table th:nth-child(3),
        .table td:nth-child(3) {
            width: 25%;
        }
    .price {
        font-size: 12px;

        padding-bottom: 5px;
    }
    #contanctTitle{
        font-size: 52px;
    }
}

@media screen and (max-width: 380px) {
    .burgerBtn {
        display: flex;
    }

    .menu>ul {
        display: none;
    }

    .bm-menu {
        background: #373a47;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
        width: 50%;
        position: fixed;
        right: 0px;
        top: 0px;
    }

    .burgerBtn .line {
        width: 20px;
        height: 3px;
        background-color: whitesmoke;
        border-radius: 5px;
    }

    .bm-burger-button {
        display: block;
        position: fixed;
        width: 25px;
        height: 25px;
        right: 40px;
        ;
        top: 20px;
    }
    .pay  img {
        width: 120px;
        height: auto;
    }

    .menu ul li {
        margin: 10px 0;
        flex-direction: column;
    }

    .wrapper {
        flex-direction: column;
    }

    .container {
        width: 90%;
    }

    .containerReverse {
        width: 90%;
        flex-direction: column;
    }

    .discription {
        width: 90%;
        padding-right: 10px;
    }

    .discription h2 {
        font-size: 30px;
    }

    .discription p,
    a {
        font-size: 16px;
    }

    .image {
        width: 90%;
    }

    .btn {
        padding: 12px 16px;
        border-radius: 10px;
        border: none;
        background-color: #1d97c1;
        color: white;
        font-size: 14px;
        cursor: pointer;
    }

    .footer {
        text-align: left;
        padding: 5%;
        height: auto;
    }

    .footer .row ul li {
        display: block;
        margin: 10px 0px;
        text-align: left;
    }

    .footer .row a i {
        margin: 0% 3%;
    }

    .logo img {
        width: 175px;
    }

    .mapStyle {
        width: 350px;
        height: 350px;
    }

    .mapDiscription {
        display: flex;
        margin: auto;
        width: 100%;

    }
    .textContactUs h2 {
        font-size: 35px;
    }

    .containerSlider {
        display: flex;
        flex-direction: column;
        width: 1000px;
        height: auto;
    }

    .slider {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .containerWrapper {
        display: flex;
        height: 10%;
        flex-direction: column;
        margin: auto;
        gap: 0;
    }

    .textWrapper {
        width: 80%;
        margin-top: 20px;
        margin-left: 0;
    }

    

    .title {
        font-size: 35px;
    }

    .title h2 {
        font-size: 30px;
    }

    .prev img,
    .next img {
        width: 40px;
        height: 40px;
    }

    .recall {
        width: 85%;
        display: flex;
        gap: 20px;
        flex-direction: column;
        line-height: 1.4;
    }

    .recall p {
        font-size: 20px;
    }

    .recall h2 {
        font-size: 24px;
    }
    .sliderTextSpecialist {
        font-size: 20px;

    }
    .sliderTextName {
        font-size: 36px;
    }

    .table{
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }
    .containerTable{
        width: 100%;
    }

    .table th, .table td {
        text-align: center;
        padding: 0px; /* Отступы для ячеек */
    }

    .table th {
        font-size: 16px;
        padding: 10px;
        
    }
    
    .table td {
        font-size: 12px;
        padding: 7px;
        padding-bottom: 5px;

    }
        .table th:nth-child(1),
        .table td:nth-child(1) {
            width: 35%;
            /* text-align: left; */
        }
    
        .table th:nth-child(2),
        .table td:nth-child(2) {
            width: 40%;
        }
    
        .table th:nth-child(3),
        .table td:nth-child(3) {
            width: 25%;
        }
    .price {
        font-size: 10px;

        padding-bottom: 5px;
    }
    #contanctTitle{
        font-size: 52px;
    }
    
}